<template>
  <div>
    <div class="main">
      <div class="title">
        <div class="tit-left">
          <span class="left-text">{{data.insuranceOrgName}}</span>
          <div class="icons">
            <div class="tab-box1">{{data.type}}</div>
            <div class="tab-box3">
              <div class="box3" v-if="data.productType[0] ==='1'">
                <img src="../../../assets/img/dui.png" alt="">
                <span>电子发票</span>
              </div>
              <div class="box3" v-if="data.productType[1] ==='1'">
                <img src="../../../assets/img/dui.png" alt="">
                <span>纸质发票</span>
              </div>
              <div class="box3" v-if="data.productType[2] ==='1'">
                <img src="../../../assets/img/dui.png" alt="">
                <span>电子保单</span>
              </div>
              <div class="box3" v-if="data.productType[3] ==='1'">
                <img src="../../../assets/img/dui.png" alt="">
                <span>纸质保单</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tit-right">
          <img :src="baseURL+'/api/file/preview?source='+data.insuranceOrgLogoUrl" alt="">
        </div>
      </div>
      <div class="content">
        <div class="text">
          <div class="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="产品详情" name="first">
                <div v-html="data.produceIntroduce" ref="produceContent" class="produceIntroduce">{{data.produceIntroduce}}</div>
                <div class="produce">
                  <!-- <div class="tit-icon">
                    <div class="icon"></div>
                    <div class="tit">产品服务</div>
                  </div> -->
                  <!-- <div class="tit-name">
                    <div class="name">公司名称:</div>
                    <div class="txt">上海仁信保险经纪有限公司</div>
                  </div>
                  <div class="tit-name">
                    <div class="name">公司简介:</div>
                    <div class="txt">上海仁信保险经纪有限公司，成立于2004年，总部设在上海。是经中国银行保险监督管理委员会批准开业的全国性、综合性保险经纪公司。公司注册资本人民币5000万元，以互联网化保险产品作为公司主营业务。作为全国保险经纪持牌机构，上海仁信垂直于数字政务行业，打造国内专业化和智能化to G、to B综合保险经纪服务创新平台，致力于成为国内数字政务领域互联网保险经纪行业新生态的初创者与建设者。</div>
                  </div> -->
                </div>
              </el-tab-pane>
              <el-tab-pane label="投保须知" name="second">
                <div ref="insuranceNotice" v-html="data.insuranceNotice"></div>
              </el-tab-pane>
              <el-tab-pane label="理赔服务" name="third">
                <div ref="settleService" v-html="data.settleService"></div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="apply">
          <div class="box1">
            <img src="../../../assets/img/ICON自助投保.png" alt="">
            <span>自助投保</span>
          </div>
          <div class="box2">
            <span>投保地区</span>
            <el-cascader ref="unit" :options="city" v-model="areaCode" :props="{ expandTrigger: 'hover' }" @change="handleChange" style="width: 300px;"></el-cascader>
          </div>
          <!-- <div class="btn" @click="handleApply" v-if="this.data.agencyOrgList.length != '0'">申请投保</div>
          <div class="btn" style="opacity:.5" v-if="this.data.agencyOrgList.length == '0'">申请投保</div> -->
          <div class="btn" @click="handleApply" v-if="isShow">申请投保</div>
          <div class="btn" style="opacity:.5" v-if="!isShow">申请投保</div>
        </div>
      </div>
    </div>
    <div class="dialog">
      <el-dialog title="申请投保" :visible.sync="dialogVisible" width="464px" :before-close="handleClose">
        <img src="../../../assets/img/icon／对话框／申请投保.png" alt="">
        <span class="tit">尊敬的客户：</span>
        <div class="msg">
          本产品在{{agencyOrgList.areaName}}地区与{{agencyOrgList.name}}交易所合作售卖，请前往{{agencyOrgList.name}}交易所平台进行购买。<br />
          网址：{{agencyOrgList.websiteUrl}}<br />
          业务联系电话：{{agencyOrgList.websiteContactsTel}}
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleShow">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div class="foot">
      <span>Copyright © 上海仁信保险经纪有限公司 版权所有 沪ICP备12012639号-1
      </span>
    </div>
  </div>
</template>

<script>
import { insureDetail, dict } from '@/api/home.js'
import Area from '@/api/city'
export default {
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      data: {},
      city: [],
      activeName: 'first',
      dialogVisible: false,
      areaCode: '',
      areaName: '',
      agencyOrgList: [],
      isShow: false,
      insuranceType: []
    }
  },
  created() {
    this.refresh()
    this.city = Area.city
  },
  methods: {
    refresh() {
      dict('insurance_type').then(res => {
        this.insuranceType = res.content
      })
      insureDetail({ id: this.$route.query.id }).then(res => {
        this.data = res
        if (this.data.productType) {
          this.data.productType = this.data.productType.split("")
        }
        this.insuranceType.forEach(item => {
          if (this.data.type === item.value) {
            this.data.type = item.label
          }
        })
      })
      setTimeout(() => {
        this.handleClick()
      }, 1000)
    },
    handleChange(value) {
      // console.log(value)
      this.areaName = this.$refs.unit.getCheckedNodes()[0].pathLabels.join(',')
      this.areaCode = value.join(',')
      this.data.agencyOrgList.forEach(item => {
        if (this.areaName === item.areaName) {
          this.isShow = true
        } else {
          this.isShow = false
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName === 'first') {
        const produceContent = this.$refs.produceContent;
        const imgs = produceContent.querySelectorAll('img')
        const images = []
        imgs.forEach((img) => {
          // img.src = 'http://10.10.1.223:8089/api/file/preview?source=202206%2F385e4e48b9334c8183013694c1e2fa08.png'
          // img.src = [ 'http://10.10.1.223:8089/api/file/preview?source=202206%2F385e4e48b9334c8183013694c1e2fa08.png']
          // this.images.push(process.env.VUE_APP_BASE_API + (img.src).substring(21))
          images.push(img.src)
          console.log(img.src, images)
        })
      } else if (this.activeName === 'second') {
        const insuranceNotice = this.$refs.insuranceNotice;
        const imgs2 = insuranceNotice.querySelectorAll('img')
        const images2 = []
        imgs2.forEach((img) => {
          images2.push(img.src)
          console.log(img.src, images2)
        })
      } else {
        const settleService = this.$refs.settleService;
        const imgs3 = settleService.querySelectorAll('img')
        const images3 = []
        imgs3.forEach((img) => {
          images3.push(img.src)
          console.log(img.src, images3)
        })
      }
    },
    handleClose() {
      this.dialogVisible = false
      this.areaCode = ''
      this.areaName = ''
      this.isShow = false
    },
    handleApply() {
      if (this.areaCode === '' || this.areaName === '') {
        this.$message.warning('请选择投保地址')
        return
      }
      insureDetail({ id: this.$route.query.id, areaCode: this.areaCode, areaName: this.areaName }).then(res => {
        this.agencyOrgList = res.agencyOrgList[0]
        this.dialogVisible = true
      })
    },
    handleShow() {
      this.dialogVisible = false;
      this.areaCode = '';
      this.areaName = ''
      this.isShow = false
    }
  }
}
</script>

<style lang="less" scoped>
@import url("../../../assets/css/ql.less");
.main {
  background: #f4f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 80px;
  .title {
    width: 1180px;
    height: 126px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    .tit-left {
      .left-text {
        font-size: 22px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .icons {
        display: flex;
        align-items: center;
        margin-top: 14px;
        .tab-box1 {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #ec6941;
          height: 30px;
          padding: 4px 10px;
          box-sizing: border-box;
          background: rgba(236, 105, 65, 0.1);
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
        }
        .tab-box3 {
          display: flex;
          justify-content: center;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          .box3 {
            display: flex;
            img {
              width: 16px;
              height: 16px;
              margin: 0 7px 0 21px;
            }
          }
        }
      }
    }
    .tit-right {
      img {
        width: 180px;
        height: 60px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
  .text {
    padding: 10px 30px 0;
    box-sizing: border-box;
    .tabs {
      width: 870px;
      ::v-deep .el-tabs__item {
        height: 66px !important;
        line-height: 66px !important;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      ::v-deep .el-tabs__item.is-active {
        color: #2e4e9a;
      }
      ::v-deep .el-tabs__active-bar {
        background-color: #2e4e9a !important;
        width: 30px !important;
        height: 4px;
        background: #2e4e9a;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        left: 18px !important;
      }
      ::v-deep .el-tabs__nav-wrap::after {
        height: 1px !important;
      }
      .produce {
        margin-top: 40px;
        .tit-icon {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .icon {
            width: 4px;
            height: 16px;
            background: #2e4e9a;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            margin-right: 13px;
          }
          .tit {
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .tit-name {
          margin-left: 10px;
          margin-bottom: 20px;
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          .name {
            font-weight: 400;
            color: #333333;
            margin-bottom: 4px;
          }
          .txt {
            // text-indent: 2em;
            line-height: 28px;
          }
        }
      }
    }
  }
  .content {
    margin-top: 20px;
    display: flex;
    .text {
      width: 930px;
      height: 1131px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
    }
    .apply {
      margin-left: 20px;
      width: 230px;
      height: 300px;
      background: url(../../../assets/img/bg.png) no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      .box1 {
        margin-top: 30px;
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .box2 {
        width: 190px;
        margin: 20px 0;
        ::v-deep .el-cascader .el-input {
          margin-top: 10px;
          width: 190px;
          height: 36px;
        }
      }
      .btn {
        width: 190px;
        height: 48px;
        background: #2e4e9a;
        border-radius: 55px 55px 55px 55px;
        opacity: 1;
        border: 1px solid #2e4e9a;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.dialog {
  .tit {
    display: block;
    margin: 16px 0;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .msg {
    line-height: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
  }
  img {
    position: absolute;
    top: 16px;
  }
  ::v-deep .el-dialog__header {
    margin-left: 50px;
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-button--primary {
    width: 80px;
    height: 32px;
    background: #2e4e9a;
    border-radius: 55px 55px 55px 55px;
    opacity: 1;
    border: 1px solid #2e4e9a;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    flex-direction: end;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .el-dialog__footer {
    display: flex;
    justify-content: flex-end;
  }
}
.foot {
  height: 64px;
  background: #393d41;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #b1a9a9;
}
</style>
